:root {
  color-scheme: dark;
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
}

video {
  border-radius: 10px;
  box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.8);
}
